<template>
  <div>
    <div>
      <h3 class="section-title">General information</h3>
      <v-row>
        <v-col md="9">
          <v-text-field
            label="Title"
            v-model.trim="contentTitle"
            class="form-item"
            :error-messages="inputError.title"
          />
        </v-col>
        <v-col md="3">
          <v-select
            class="form-item"
            label="Type"
            :items="contentTypeList"
            v-model="contentType"
          />
        </v-col>
      </v-row>
    </div>
    <v-text-field
      label="Subtitle"
      v-model.trim="contentSubTitle"
      class="form-item"
      :error-messages="inputError.subTitle"
    />
    <v-text-field
      label="Short title (optional)"
      v-model.trim="contentShortTitle"
      class="form-item"
      hint="This title will be displayed on content preview card instead of main title."
      :hint-persistent="true"
    />
    <v-textarea
      class="form-item"
      label="Description"
      v-model.trim="contentDescription"
      dense
      auto-grow
      rows="4"
      counter
      :error-messages="inputError.description"
    />
    <h3 class="section-title">Media</h3>
    <v-row>
      <v-col lg="6" cols="12">
        <v-file-input
          prepend-icon="mdi-image"
          label="Upload image"
          clearable
          accept="image/*"
          @change="setPreviewImage"
          v-model="contentImage"
          class="form-item"
          :error-messages="inputError.image"
          hint="Only image formats, such as jpeg or svg, are accepted."
        ></v-file-input>
      </v-col>
      <v-col lg="6" cols="12">
        <v-text-field
          prepend-icon="mdi-video"
          label="Video link"
          v-model.trim="contentVideoUrl"
          class="form-item"
          clearable
          hint="Only support YouTube, Vimeo and Dailymotion videos."
          persistent-hint
        />
      </v-col>
    </v-row>
    <div
      class="content-media-demo"
      :key="previewContentImage + contentVideoUrl"
    >
      <image-video
        :image-url="previewContentImage"
        :video-url="contentVideoUrl"
      />
    </div>
    <div>
      <h3 class="section-title">Action button</h3>
      <v-row>
        <v-col md="4" cols="12">
          <v-text-field
            label="Action title"
            v-model.trim="contentActionTitle"
            class="form-item"
            :error-messages="inputError.actionTitle"
          />
        </v-col>
        <v-col md="8" cols="12">
          <v-text-field
            label="Action link"
            v-model.trim="contentActionLink"
            class="form-item"
            :error-messages="inputError.actionLink"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
import EventBus from "../event-bus";
import ImageVideo from "../../../app/components/media/ImageVideo.vue";
import { required, url } from "vuelidate/lib/validators";
import { isValidImageFormat } from "../../../app/utils/validation-helper";
import { getEmbedVideoUrl } from "../../../app/utils/video-helper";

export default {
  name: "ContentForm",
  components: {
    "image-video": ImageVideo,
  },
  data() {
    return {
      contentTitle: this.content.title || "",
      contentType:
        this.content.type && this.content.type !== ""
          ? this.content.type
          : "education",
      contentSubTitle: this.content.sub_title || "",
      contentShortTitle: this.content.short_title || "",
      contentImage: null,
      contentDescription: this.content.body || "",
      contentActionTitle: this.content.action_title || "",
      contentActionLink: this.content.action_url || "",
      previewContentImage: this.content.image_url || "",
      contentVideoUrl: this.content.video_url || "",
      inputError: {
        contentTitle: "",
        contentSubTitle: "",
        contentImage: "",
        contentDescription: "",
        contentActionTitle: "",
        contentActionLink: "",
      },
    };
  },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  validations: {
    contentTitle: { required },
    contentSubTitle: { required },
    contentImage: {
      validFormat: (val) => val === null || isValidImageFormat(val),
    },
    contentDescription: { required },
    contentActionTitle: { required },
    contentActionLink: { required, url },
  },
  mounted() {
    EventBus.$on(
      "submit",
      function () {
        this.submit();
      }.bind(this)
    );
    EventBus.$on(
      "preview",
      function () {
        this.preview();
      }.bind(this)
    );
  },
  computed: {
    contentTypeList() {
      return [
        { text: "Education", value: "education" },
        { text: "Funding", value: "funding" },
        { text: "Grant", value: "grant" },
        { text: "Event", value: "event" },
      ];
    },
  },
  watch: {
    contentTitle() {
      this.validateTitle();
    },
    contentSubTitle() {
      this.validateSubTitle();
    },
    contentImage() {
      this.validateImage();
    },
    contentDescription() {
      this.validateDescription();
    },
    contentActionTitle() {
      this.validateActionTitle();
    },
    contentActionLink() {
      this.validateActionLink();
    },
    contentVideoUrl(value) {
      this.contentVideoUrl = getEmbedVideoUrl(value);
    },
  },
  methods: {
    validateTitle() {
      if (this.$v.contentTitle.$invalid) {
        this.inputError.title = "Title is required";
        this.contentTitle = " ";
      } else {
        this.inputError.title = "";
      }
    },
    validateSubTitle() {
      if (this.$v.contentSubTitle.$invalid) {
        this.inputError.subTitle = "Subtitle is required";
      } else {
        this.inputError.subTitle = "";
      }
    },
    validateImage() {
      if (this.$v.contentImage.$invalid) {
        this.inputError.image = "Invalid image format";
      } else {
        this.inputError.image = "";
      }
    },
    validateDescription() {
      if (this.$v.contentDescription.$invalid) {
        this.inputError.description = "Description is required";
      } else {
        this.inputError.description = "";
      }
    },
    validateActionTitle() {
      if (this.$v.contentActionTitle.$invalid) {
        this.inputError.actionTitle = "Action title is required";
      } else {
        this.inputError.actionTitle = "";
      }
    },
    validateActionLink() {
      if (this.$v.contentActionLink.$invalid) {
        this.inputError.actionLink = "Action link is required";
      } else {
        this.inputError.actionLink = "";
      }
    },
    validate() {
      if (this.$v && this.$v.$invalid) {
        this.validateTitle();
        this.validateSubTitle();
        this.validateImage();
        this.validateDescription();
        this.validateActionTitle();
        this.validateActionLink();
        return false;
      }
      return true;
    },
    submit() {
      if (!this.validate()) {
        return;
      }
      this.$emit(
        "submit",
        {
          title: this.contentTitle,
          type: this.contentType,
          sub_type: "",
          sub_title: this.contentSubTitle,
          short_title: this.contentShortTitle,
          body: this.contentDescription,
          action_title: this.contentActionTitle,
          action_url: this.contentActionLink,
          video_url: this.contentVideoUrl,
        },
        this.contentImage
      );
    },
    setPreviewImage(file) {
      try {
        if (file) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.previewContentImage = e.target.result;
          };
          reader.readAsDataURL(file);
        } else {
          this.contentImage = null;
          this.previewContentImage = "";
        }
      } catch (error) {
        this.$store.dispatch(
          "callAlert",
          error.message || "Can not upload image."
        );
      }
    },
    preview() {
      if (!this.validate()) {
        return;
      }
      this.$emit("preview", {
        title: this.contentTitle,
        type: this.contentType,
        sub_type: "",
        image_url: this.previewContentImage,
        video_url: this.contentVideoUrl,
        sub_title: this.contentSubTitle,
        short_title: this.contentShortTitle,
        body: this.contentDescription,
        action_title: this.contentActionTitle,
        action_url: this.contentActionLink,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.section-title {
  margin-top: 10px;
}
.form-group {
  display: flex;
  justify-content: space-between;
}
.content-image {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.content-media-demo {
  margin-bottom: 25px;
}
@media (max-width: $breakpoint-mobile) {
  .form-group,
  .content-image {
    display: block;
  }
  .content-media-demo {
    margin-bottom: 0;
  }
}
</style>
