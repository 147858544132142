var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        fullscreen: "",
        "hide-overlay": "",
        transition: "dialog-bottom-transition"
      },
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "preview-container", attrs: { "min-height": "100vh" } },
        [
          _c(
            "v-toolbar",
            { attrs: { flat: "", dark: "", color: "inqliGreen" } },
            [
              _c(
                "v-icon",
                {
                  staticClass: "close-preview-icon",
                  attrs: { icon: "", dark: "" },
                  on: {
                    click: function($event) {
                      _vm.dialog = false
                    }
                  }
                },
                [_vm._v("mdi-close")]
              ),
              _c("v-toolbar-title", { staticClass: "preview-title ml-2" }, [
                _vm._v("Preview content")
              ]),
              _c("v-spacer"),
              _c("v-toolbar-items", [
                _c(
                  "div",
                  { staticClass: "preview-button-list" },
                  [
                    _c(
                      "outline-button",
                      {
                        on: {
                          click: function($event) {
                            _vm.dialog = false
                          }
                        }
                      },
                      [_vm._v("Close")]
                    ),
                    _c(
                      "app-button",
                      { staticClass: "ml-4", on: { click: _vm.publish } },
                      [_vm._v(_vm._s(this.publishLabel))]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "content-container" },
            [
              _c("content-detail", {
                attrs: {
                  content: _vm.content,
                  author: _vm.author,
                  "share-disabled": true
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }