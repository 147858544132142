<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card class="preview-container" min-height="100vh">
      <v-toolbar flat dark color="inqliGreen">
        <v-icon icon dark @click="dialog = false" class="close-preview-icon"
          >mdi-close</v-icon
        >
        <v-toolbar-title class="preview-title ml-2"
          >Preview content</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <div class="preview-button-list">
            <outline-button @click="dialog = false">Close</outline-button>
            <app-button @click="publish" class="ml-4">{{
              this.publishLabel
            }}</app-button>
          </div>
        </v-toolbar-items>
      </v-toolbar>

      <div class="content-container">
        <content-detail
          :content="content"
          :author="author"
          :share-disabled="true"
        />
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import ContentDetail from "../../../contents/components/ContentDetail.vue";
import Button from "../../../app/components/buttons/Button";
import OutlineButton from "../../../app/components/buttons/OutlineButton";

export default {
  name: "ContentPreview",
  components: {
    "content-detail": ContentDetail,
    "outline-button": OutlineButton,
    "app-button": Button,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    content: {
      type: Object,
      default: () => {},
    },
    author: {
      type: Object,
      default: () => {},
    },
    publishLabel: {
      type: String,
      default: "Publish",
    },
  },
  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    publish() {
      this.$emit("publish");
      this.dialog = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.content-container {
  padding: 15px;
  min-height: 92vh;
  max-width: 620px;
  margin: auto;
}
.preview-title {
  font-size: 17px;
  font-weight: 600;
}
.preview-container {
  background-color: $inqliBackground;
}
.preview-button-list {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>
