import { render, staticRenderFns } from "./ContentForm.vue?vue&type=template&id=92442e58&scoped=true&"
import script from "./ContentForm.vue?vue&type=script&lang=js&"
export * from "./ContentForm.vue?vue&type=script&lang=js&"
import style0 from "./ContentForm.vue?vue&type=style&index=0&id=92442e58&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92442e58",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VCol,VFileInput,VRow,VSelect,VTextField,VTextarea})


/* hot reload */
if (module.hot) {
  var api = require("/home/matt/Documents/inqli/product/web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('92442e58')) {
      api.createRecord('92442e58', component.options)
    } else {
      api.reload('92442e58', component.options)
    }
    module.hot.accept("./ContentForm.vue?vue&type=template&id=92442e58&scoped=true&", function () {
      api.rerender('92442e58', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/system-admin/contents/components/ContentForm.vue"
export default component.exports