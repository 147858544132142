var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        [
          _c("h3", { staticClass: "section-title" }, [
            _vm._v("General information")
          ]),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { md: "9" } },
                [
                  _c("v-text-field", {
                    staticClass: "form-item",
                    attrs: {
                      label: "Title",
                      "error-messages": _vm.inputError.title
                    },
                    model: {
                      value: _vm.contentTitle,
                      callback: function($$v) {
                        _vm.contentTitle =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "contentTitle"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { md: "3" } },
                [
                  _c("v-select", {
                    staticClass: "form-item",
                    attrs: { label: "Type", items: _vm.contentTypeList },
                    model: {
                      value: _vm.contentType,
                      callback: function($$v) {
                        _vm.contentType = $$v
                      },
                      expression: "contentType"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("v-text-field", {
        staticClass: "form-item",
        attrs: { label: "Subtitle", "error-messages": _vm.inputError.subTitle },
        model: {
          value: _vm.contentSubTitle,
          callback: function($$v) {
            _vm.contentSubTitle = typeof $$v === "string" ? $$v.trim() : $$v
          },
          expression: "contentSubTitle"
        }
      }),
      _c("v-text-field", {
        staticClass: "form-item",
        attrs: {
          label: "Short title (optional)",
          hint:
            "This title will be displayed on content preview card instead of main title.",
          "hint-persistent": true
        },
        model: {
          value: _vm.contentShortTitle,
          callback: function($$v) {
            _vm.contentShortTitle = typeof $$v === "string" ? $$v.trim() : $$v
          },
          expression: "contentShortTitle"
        }
      }),
      _c("v-textarea", {
        staticClass: "form-item",
        attrs: {
          label: "Description",
          dense: "",
          "auto-grow": "",
          rows: "4",
          counter: "",
          "error-messages": _vm.inputError.description
        },
        model: {
          value: _vm.contentDescription,
          callback: function($$v) {
            _vm.contentDescription = typeof $$v === "string" ? $$v.trim() : $$v
          },
          expression: "contentDescription"
        }
      }),
      _c("h3", { staticClass: "section-title" }, [_vm._v("Media")]),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { lg: "6", cols: "12" } },
            [
              _c("v-file-input", {
                staticClass: "form-item",
                attrs: {
                  "prepend-icon": "mdi-image",
                  label: "Upload image",
                  clearable: "",
                  accept: "image/*",
                  "error-messages": _vm.inputError.image,
                  hint: "Only image formats, such as jpeg or svg, are accepted."
                },
                on: { change: _vm.setPreviewImage },
                model: {
                  value: _vm.contentImage,
                  callback: function($$v) {
                    _vm.contentImage = $$v
                  },
                  expression: "contentImage"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { lg: "6", cols: "12" } },
            [
              _c("v-text-field", {
                staticClass: "form-item",
                attrs: {
                  "prepend-icon": "mdi-video",
                  label: "Video link",
                  clearable: "",
                  hint: "Only support YouTube, Vimeo and Dailymotion videos.",
                  "persistent-hint": ""
                },
                model: {
                  value: _vm.contentVideoUrl,
                  callback: function($$v) {
                    _vm.contentVideoUrl =
                      typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "contentVideoUrl"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          key: _vm.previewContentImage + _vm.contentVideoUrl,
          staticClass: "content-media-demo"
        },
        [
          _c("image-video", {
            attrs: {
              "image-url": _vm.previewContentImage,
              "video-url": _vm.contentVideoUrl
            }
          })
        ],
        1
      ),
      _c(
        "div",
        [
          _c("h3", { staticClass: "section-title" }, [_vm._v("Action button")]),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { md: "4", cols: "12" } },
                [
                  _c("v-text-field", {
                    staticClass: "form-item",
                    attrs: {
                      label: "Action title",
                      "error-messages": _vm.inputError.actionTitle
                    },
                    model: {
                      value: _vm.contentActionTitle,
                      callback: function($$v) {
                        _vm.contentActionTitle =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "contentActionTitle"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                { attrs: { md: "8", cols: "12" } },
                [
                  _c("v-text-field", {
                    staticClass: "form-item",
                    attrs: {
                      label: "Action link",
                      "error-messages": _vm.inputError.actionLink
                    },
                    model: {
                      value: _vm.contentActionLink,
                      callback: function($$v) {
                        _vm.contentActionLink =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "contentActionLink"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }